@import "@angular/cdk/overlay-prebuilt";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-primary {
        @apply cursor-pointer justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:bg-gray-300 disabled:cursor-default;
    }

    .input-text {
        @apply block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm;
    }
}

html {
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
  }
  
  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  body {
    margin: 0; 
    font-family: 'Open Sans', sans-serif;
  }
